<template>
    <label
        :class="{ [`form-${type}`]: true, [`input-${size}`]: true  }"
    >
        <input
            v-bind="$props"
            v-on="$listeners"
        />
        <i class="form-icon"></i><slot></slot>
    </label>
</template>

<script>
export default {
    name: "BaseToggleInput",
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        checked: {
            default: false
        },
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(radio|checkbox)$/i),
            default: "radio"
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
