<template>
    <div
        class="form-autocomplete nibnut-token-input"
    >
        <div class="form-autocomplete-input form-input">
            <slot name="selection">
                <div
                    v-for="token in value"
                    :key="token"
                    class="chip"
                >
                    {{ token }}
                    <a
                        href
                        class="btn btn-clear"
                        aria-label="Close"
                        role="button"
                        @click.prevent="delete_token(token)"
                    ></a>
                </div>
            </slot>

            <base-input
                ref="field"
                :id="id"
                type="text"
                :name="name"
                :value="query"
                :disabled="disabled"
                :required="required"
                @touchstart="touchstart"
                @keydown="keydown"
                @change="changed"
                @blur="blur"
            />
        </div>
        <ul
            v-if="available_options.length"
            :id="`${id}-menu`"
            ref="menu"
            role="listbox"
            class="menu"
        >
            <li
                v-for="(option, index) in available_options"
                :key="option"
                role="option"
                tabindex="-1"
                class="menu-item"
            >
                <a
                    href
                    tabindex="-1"
                    :class="{ active: selected_index === index }"
                    @mousedown="pick(option)"
                    @click.prevent
                >
                    <slot name="option" :option="option">
                        <span v-html="highlighted_label(option)"></span>
                    </slot>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import BaseInput from "./BaseInput"

export default {
    name: "BaseTokenInput",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        BaseInput
    },
    methods: {
        highlighted_label (option) {
            let label = option || ""
            if(label && this.query) {
                const length = this.query.length
                const index = label.toLowerCase().indexOf(this.query.toLowerCase())
                if(index >= 0) label = `${label.substring(0, index)}<strong class="text-primary">${label.substring(index, index + length)}</strong>${label.substring(index + length)}`
            }
            return label
        },
        keydown (event) {
            if(this.alphanumeric && this.iOS) this.touching = false
            // " " or "," => add current query and reset
            switch (event.key) {
            case " ":
            case ",":
            case "Enter":
                if(this.query) {
                    if(this.selected_index >= 0) {
                        this.pick(this.available_options[this.selected_index])
                    } else if(!this.tokenValidator || this.tokenValidator(this.query)) {
                        this.pick(event.target.value)
                    } else if(this.available_options.length) {
                        this.pick(this.available_options[0])
                    }
                }
                event.preventDefault()
                break

            case "Escape":
                this.query = ""
                event.target.value = ""
                event.target.blur()
                event.stopPropagation()
                event.preventDefault()
                break

            case "Backspace":
                if(!event.target.value && !!this.value.length) {
                    this.delete_token(this.value.at(-1))
                    this.query = ""
                } else this.query = event.target.value
                break

            case "ArrowDown":
            case "ArrowUp": {
                const available_options = this.available_options
                if(available_options.length) {
                    if(this.selected_index >= 0) {
                        if(event.key === "ArrowDown") this.selected_index += 1
                        else this.selected_index -= 1
                    } else {
                        if(event.key === "ArrowDown") this.selected_index = 0
                        else this.selected_index = available_options.length - 1
                    }
                    if(this.selected_index < 0) this.selected_index = available_options.length - 1
                    else if(this.selected_index >= available_options.length) this.selected_index = 0
                }
                break
            }

            case "Shift":
            case "Tab":
            case "Control":
            case "Alt":
            case "Meta":
            case "ArrowLeft":
            case "ArrowRight":
                break

            default:
                if(!event.altKey && !event.ctrlKey && !event.metaKey) {
                    this.selected_index = -1
                    this.query = `${event.target.value}${event.key}`
                    event.preventDefault()
                }
                break
            }
        },
        changed (event) {
            this.query = event.target.value
        },
        pick (option) {
            this.query = option
            this.add_token()
        },
        add_token () {
            if(this.query && (!this.tokenValidator || this.tokenValidator(this.query))) {
                this.$emit("input", this.query)
                this.query = ""
            }
        },
        delete_token (token) {
            this.$emit("delete", token)
        },
        blur (event) {
            if(this.alphanumeric && this.iOS) this.touching = false
            this.selected_index = -1
            this.add_token()
            this.query = ""
        }
    },
    computed: {
        available_options () {
            if(!this.options || !this.options.length || !this.query || (this.tokenValidator && !this.tokenValidator(this.query, true))) return []
            const query = this.query.toLowerCase()
            const options = this.options.filter(option => {
                return option.toLowerCase().indexOf(query) >= 0
            })
            return options
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // array of tokens
            type: Array,
            default () {
                return []
            }
        },
        tokenValidator: {
            default: null
        },
        options: {
            type: Array,
            default () {
                return []
            }
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            query: "",
            selected_index: -1
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-autocomplete.nibnut-token-input {
    .form-autocomplete-input {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}
</style>
