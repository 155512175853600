import DOMPurify from "dompurify"

if(window.trustedTypes && window.trustedTypes.createPolicy) {
    window.trustedTypes.createPolicy("otto-default", {
        createHTML: (string, sink) => DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true })
    })
}

const is_nibnut_component = {
    methods: {
        has_slot (slot_name, slot_props) {
            if(!!this.$slots && !!this.$slots[slot_name]) return true
            const nodes = !!this.$scopedSlots && !!this.$scopedSlots[slot_name] && this.$scopedSlots[slot_name](slot_props)
            return nodes && !!nodes.length
        }
    },
    computed: {
        identifier () {
            return this.id || this.name
        }
    }
}

export default is_nibnut_component
